import React from 'react';
import '../../App.css';
import './Sponsorship.css';
import Footer from '../Footer';
import { useTranslation } from "react-i18next";

export default function Sponsorship() {

  const { t } = useTranslation();


  return <div className="spons-container">
    <div className="spons-form">
      <h1 >{t('title.sponsorship')}</h1>
      <br></br>
      <p>{t('text.sponsorship1')}</p>
    </div>
    <Footer />
  </div>
}
