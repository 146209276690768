import React from "react"

import AppStore from "../assets/images/Apple.svg";
import AppStoreLight from "../assets/images/Apple-light.svg";
import StoreButton from "./StoreButton"
import { useTranslation } from "react-i18next";


/**
 *
 * @param {string} theme Choose a theme between dark and light
 * @param {number} height Controls the height of the button
 * @param {number} width Controls the width of the button
 * @param {string} title Set custom title for the button
 * @param {string} className Add className to the button in order to customize the button appearance
 * @param {string} url Add your store url to the button
 * @example <GooglePlayButton theme="light" height={70} width={130} className="button-container" url="https://play.google.com/" />
 * @returns
 */
export const AppStoreButton = ({
  theme = "dark",
  height,
  title = "Download on the",
  width,
  className,
  url
}) => {

  const { t } = useTranslation();

  return (
    <StoreButton
      theme={theme}
      height={height}
      width={width}
      url={url}
      storeName={"App Store"}
      logo={theme === "dark" ? AppStoreLight : AppStore}
      className={className}
      title={t('text.downloadOnThe')}
    />
  )
}

