import React, { useState, useEffect } from 'react';
import '../App.css';
import './HeroSection.css';
import { GooglePlayButton } from "./GooglePlayButton";
import { AppStoreButton } from './AppStoreButton';
import { useTranslation } from "react-i18next";


function HeroSection() {

  const { t } = useTranslation();

  var [direction, setDirection] = useState('row');

  const changeDirection = () => {
    if (window.innerWidth <= 960) {
      setDirection('column');
    } else {
      setDirection('row');
    }
  };

  useEffect(() => {
    changeDirection();
  }, []);

  window.addEventListener('resize', changeDirection);

  return (
    <div className='hero-container'>
      <h1>{t('title.welcomeText')}</h1>
      <p>{t('text.whatAreYouWait')}</p>
      <div className='hero-btns'>
        <div
          className="store-buttons-container"
          style={{
            gap: 30,
            flexDirection: direction,
          }}
        >

          <GooglePlayButton url="https://play.google.com/store/apps/details?id=com.QuartsikCo.ObrazkovaAbeceda&pcampaignid=web_share"></GooglePlayButton>
          <AppStoreButton url="https://apps.apple.com/cz/app/mu%C5%BEi-proti-%C5%BEen%C3%A1m/id1566188040"></AppStoreButton>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
