import React, { useState, useEffect } from 'react'
import { Button } from './Button';
import { Link } from 'react-router-dom'
import './Navbar.css';
import logo from '../assets/images/logo_QT_green.png'
import secLogo from '../assets/images/QT_header.png'
import { useTranslation } from "react-i18next";

function Navbar() {

  const { i18n } = useTranslation();

  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState("cs");
  const [newLanguage, setNewLanguage] = useState("en");

  const changeLanguageCZ = (lng) => {

    if (currentLanguage === "cs") {
      document.getElementById("lngBtnEn").classList.add("nav-lang-selected");
      document.getElementById("lngBtnCz").classList.remove("nav-lang-selected");
      setCurrentLanguage("en");
      i18n.changeLanguage("en");
    } else {
      document.getElementById("lngBtnCz").classList.add("nav-lang-selected");
      document.getElementById("lngBtnEn").classList.remove("nav-lang-selected");
      setCurrentLanguage("cs");
      i18n.changeLanguage("cs");
    }
  }

  const changeLanguageEN = (lng) => {
    if (currentLanguage === "cs") {
      document.getElementById("lngBtnEn").classList.add("nav-lang-selected");
      document.getElementById("lngBtnCz").classList.remove("nav-lang-selected");
      setCurrentLanguage("en");
      i18n.changeLanguage("en");
    } else {
      document.getElementById("lngBtnCz").classList.add("nav-lang-selected");
      document.getElementById("lngBtnEn").classList.remove("nav-lang-selected");
      setCurrentLanguage("cs");
      i18n.changeLanguage("cs");
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(newLanguage);
    if (currentLanguage === "cs") {
      setNewLanguage("cs");
      setCurrentLanguage("en");
    } else {
      setNewLanguage("en");
      setCurrentLanguage("cs");
    }
  }

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [mobileHidden, setLogo] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const showLogo = () => {
    if (window.innerWidth <= 960) {
      setLogo(true);
    } else {
      setLogo(false);
    }
  };

  const getCurrentLng = () => {
    var lng = i18n.language;
    if (lng === "en") {
      setCurrentLanguage("en");
      i18n.changeLanguage("en");
      document.getElementById("lngBtnEn").classList.add("nav-lang-selected");
      document.getElementById("lngBtnCz").classList.remove("nav-lang-selected");
    } else {
      setCurrentLanguage("cs");
      i18n.changeLanguage("cs");
      document.getElementById("lngBtnCz").classList.add("nav-lang-selected");
      document.getElementById("lngBtnEn").classList.remove("nav-lang-selected");
    }
  };

  useEffect(() => {
    getCurrentLng();
    showButton();
    showLogo();
  }, []);


  window.addEventListener('resize', showButton);
  window.addEventListener('resize', showLogo);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={handleClick}>
            <img width="50px" src={logo}></img> &nbsp;&nbsp;  <img hidden={mobileHidden} width="250px" src={secLogo}></img>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} ></i>
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                {t('title.home')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('title.contact')}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {t('title.about')}
              </Link>
            </li>

            {/* <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Spolupráce
              </Link>
            </li> */}

          </ul>

          {!mobileHidden &&
            <div className='nav-social-icons' >
              <Link
                className='social-icon-link facebook'
                to='https://www.facebook.com/people/Questown/61558509303549/'
                target='_blank'
                aria-label='Facebook'
              >
                <i className='fab fa-facebook-f' />
              </Link>
              <Link
                className='social-icon-link instagram'
                to='https://www.instagram.com/questownGame'
                target='_blank'
                aria-label='Instagram'
              >
                <i className='fab fa-instagram' />
              </Link>
              <Link
                className='social-icon-link twitter'
                to='https://twitter.com/questownGame'
                target='_blank'
                aria-label='Twitter'
              >
                <i className='fab fa-x-twitter' />
              </Link>
              <Link
                className='social-icon-link twitter'
                to='https://www.linkedin.com/showcase/questown/'
                target='_blank'
                aria-label='LinkedIn'
              >
                <i className='fab fa-linkedin' />
              </Link>
            </div>}
        </div>
        <Link id="lngBtnCz" className='nav-lang-cz nav-lang nav-lang-selected' onClick={changeLanguageCZ}>
          CZ
        </Link>
        <Link id="lngBtnEn" className='nav-lang-en nav-lang ' onClick={changeLanguageEN}>
          EN
        </Link>
      </nav>
    </>
  )

}

export default Navbar