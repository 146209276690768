import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import csJSON from './locale/CZ.json'
import enJSON from './locale/EN.json'

i18n
   .use(LanguageDetector)
   .use(initReactI18next).init({
      fallbackLng: "en",
      supportedLngs: ['cs', 'en'],
      resources: {
         cs: { ...csJSON },
         en: { ...enJSON },
      },
      interpolation: {
         escapeValue: false // react already safes from xss
      },

   });