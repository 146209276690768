import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import qt0 from '../assets/images/QT0.png'
import qt1 from '../assets/images/QT1.png'
import qt2 from '../assets/images/QT2.png'
import qt3 from '../assets/images/QT3.png'
import qt4 from '../assets/images/QT4.png'


import { useTranslation } from "react-i18next";

function Cards() {

  const { t } = useTranslation();

  return (
    <div className='cards'>
      <h1>{t('text.exploreApp')}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={qt1}
              text={t('text.cardText1')}
              label={t('text.adventure')}
              path='/about'
            />
            <CardItem
              src={qt0}
              text={t('text.cardText2')}
              label={t('text.history')}
              path='/about'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={qt3}
              text={t('text.cardText3')}
              label={t('text.mystery')}
              path='/about'
            />
            <CardItem
              src={qt2}
              text={t('text.cardText4')}
              label={t('text.adventure')}
              path='/about'
            />
            <CardItem
              src={qt4}
              text={t('text.cardText5')}
              label={t('text.pointOfInterest')}
              path='/about'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
