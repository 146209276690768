import React from "react"

import GooglePlay from "../assets/images/Google-Play.svg"
import StoreButton from "./StoreButton"
import { useTranslation } from "react-i18next";

/**
 *
 * @param {string} theme Choose a theme between dark and light
 * @param {number} height Controls the height of the button
 * @param {number} width Controls the width of the button
 * @param {string} title Set custom title for the button
 * @param {string} className Add className to the button in order to customize the button appearance
 * @param {string} url Add your store url to the button
 * @example <GooglePlayButton theme="light" height={70} width={130} className="button-container" url="https://play.google.com/" />
 * @returns
 */
export const GooglePlayButton = ({
  theme = "dark",
  height,
  title = "GET IT ON",
  width,
  className,
  url
}) => {

  const { t } = useTranslation();

  return (
    <StoreButton
      theme={theme}
      height={height}
      width={width}
      url={url}
      storeName={"Google Play"}
      logo={GooglePlay}
      className={className}
      title={t('text.getItOn')}
    />
  )
}

