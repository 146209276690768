import React from "react"

import "./StoreButton.css"

const StoreButton = ({
  theme = "light",
  height = 85,
  width = 260,
  border,
  logo,
  storeName,
  title,
  url,
  className
}) => {
  return (
    <div
      onClick={() => url && window.open(url, "_blank")}
      style={{
        height: height,
        width: width,
        borderRadius: border
      }}
      className={`button-container button-container-${theme} ${className}`}
    >
      <img src={logo} alt={storeName} />
      <div className="button-text-container">
        <span className="button-title">{title}</span>
        <span className="button-store-name">{storeName}</span>
      </div>
    </div>
  )
}

export default StoreButton