
import React, { useState, useEffect }  from 'react';
import Navbar from './components/Navbar';
import ScrollToTop from './ScrollToTop';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import About from './components/pages/About';
import SignUp from './components/pages/SignUp';
import Store from './components/pages/Store';
import { BrowserRouter, Routes, Route, ScrollRestoration } from 'react-router-dom';
import './App.css';
import Sponsorship from './components/pages/Sponsorship';
import { FaArrowUp } from "react-icons/fa";

function App() {

  const [showButton, setShowButton] = useState(false);

  const onScroll = () => {
    let pixelsFromTop = window.scrollY;
    let documentHeight = document.body.clientHeight;
    let windowHeight = window.innerHeight;
    let difference = documentHeight - windowHeight;
    let percentage = (100 * pixelsFromTop) / difference;

    // Scroll to top button logic
    window.scrollY > 350 ? setShowButton(true) : setShowButton(false);
  };


  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path='/store' element={<Store />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/sponsorship' element={<Sponsorship />} />
          <Route path='/sign-up' component={SignUp} />
        </Routes>
      </BrowserRouter>
      <FaArrowUp className={showButton ? "showButton" : "hidden"}
        onClick={scrollToTop}/>


    </>
  );
}

export default App;
