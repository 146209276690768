import React, { useEffect } from 'react';
import '../../App.css';
import './Sponsorship.css';
import Footer from '../Footer';
import { useTranslation } from "react-i18next";

const AppStore = () => {

  const { t } = useTranslation();

  useEffect(() => {
    // Funkce pro detekci iOS zařízení
    const isIOS = () => {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    // Funkce pro detekci Android zařízení
    const isAndroid = () => {
      return /Android/i.test(navigator.userAgent);
    };

    // URL pro Apple Store a Google Play
    const appleStoreUrl = "https://apps.apple.com/cz/app/mu%C5%BEi-proti-%C5%BEen%C3%A1m/id1566188040";
    const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.QuartsikCo.ObrazkovaAbeceda&pcampaignid=web_share";
    const questownUrl = "https://www.questown.cz/"

    // Podmínka, která uživatele přesměruje na správný obchod
    if (isIOS()) {
      window.location.href = appleStoreUrl;
    } else if (isAndroid()) {
      window.location.href = googlePlayUrl;
    } else {
      // Pokud není rozpoznán ani iOS, ani Android, můžeš uživatele ponechat na stránce nebo ukázat nějaké upozornění
      window.location.href = questownUrl;
    }
  }, []);

  return <div>{t('text.redirToStore')}</div>;
};

export default AppStore;