import React, { useState } from 'react';
import '../../App.css';
import { Button } from '../Button';
import './Contact.css';
import axios from 'axios';
import Footer from '../Footer';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

export default function Contact() {

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [captchaDone, setCaptchaDone] = useState(false);
  const { successMessage, errorMessage } = String;

  const handleChange = (event) => {

    if (event != null) {
      setCaptchaDone(true);
    }

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // Zde můžete implementovat odeslání dat na server nebo jinou požadovanou funkcionalitu
    if (name === "" || email === "" || message === "") {
      var alertText = ""
      if (name === "") {
        alertText = "Není vyplněno Vaše jméno\n"
      }
      if (email === "") {
        alertText = alertText + "Není vyplněna Vaše emailová adresa\n"
      }
      if (message === "") {
        alertText = alertText + "Není vyplněna Vaše zpráva\n"
      }
      alert(alertText);
      return;
    }

    var formData = {
      firstName: name,
      email: email,
      msg: message
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}`,
      headers: { "content-type": "application/json" },
      data: formData
    })
      .then(result => {
        if (result.data.sent) {
          setmailSent(result.data.sent)
          setError(false)
        } else {
          setError(true)
        }
      })
      .catch(error => setError(error.message));

  };

  return (
    <div className="contact-container">
      <div className="contact-view">
        {!mailSent && <div className="contact-form">
          <h1 className="empty-title"></h1>
          <h1 className="contact-title">{t('text.contactForm')}</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <label htmlFor="name">{t('text.yourName')}:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-row">
              <label htmlFor="email">{t('text.email')}:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-row">
              <label htmlFor="message">{t('text.message')}:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>
            <Button className='btns'
              onClick={handleSubmit}
              enabled={captchaDone ? false : true}
              buttonStyle='btn--outline'
              buttonSize='btn--medium'
              type="submit">{t('text.send')}</Button>
            <div className='capchaHeight'>
              <div style={{ display: captchaDone ? "none" : "flex" }} className='capcha'>
                <ReCAPTCHA
                  sitekey="6LeL3q0pAAAAAJa_fCnghFgorNWdlRfx1hmi1J58"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>

              {error && <div className="error"><h1>Při odesílání došlo k chybě</h1></div>}
            </div>
          </form>

        </div>}
        {mailSent && <div className="sucsess">
          <div className="send-text">
            <h1 className="contact-title">ODESLÁNO, DÍKY!</h1></div>
        </div>}

      </div>
      <Footer />
    </div>
  );

}
